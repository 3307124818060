<template>
	<div class="myCenter">
		<div class="myCenter" v-for="(me,meidx) of DataList" v-if="meidx==0">
			<!--个人信息-->
			<div class="userInfo">
				<div class="code">
					
					<img src="../../public/static/images/code.png" width="31" height="31" @click="ShowQrcode" />
				</div>
				<div class="code2" v-if="me.groupid==1"  @click="ShowMyQrcode">
					业务员<br />二维码
				</div>
				<div class="userImg" @click="refreshToken">
					<img :src="me.headimgurl" height="82" width="82" style="border: 2px solid #FFFFFF;border-radius: 15px;" />
				</div>
				<div class="userName">{{me.nickname}}</div>
				<div class="userPoint"><span style="" @click="pointChange">会员积分：{{me.nowscore}}</span><span @click="myCoupon" style="padding-left: 20px;">优惠券:{{couponCount}}张</span></div>
				<div style="position: absolute; width: 100%; bottom: 0; padding-bottom: 6px; color: #fff; font-size: 12px;">
					<div v-show="me.ywyphone!=''">业务员手机号：<a :href="'tel:'+me.ywyphone">{{me.ywyphone}}</a></div>
					<div @click="ShowYwyQrcode()" v-show="me.ywywechat!=''||me.ywyqrcode!=''">业务员微信号：{{me.ywywechat}} <img v-if="me.ywyqrcode" src="../../public/static/images/code.png" style="width: 12px; height: 12px; vertical-align: middle; transform: translateY(-1px);" /> </div>

				</div>
			</div>
			<!--分类-->
			<div class="classifyList">
				<div class="classifyType" v-if="me.zj_status<=0" @click="myInfo2">
					<div class="imgs">
						<img src="../../public/static/images/persondata.png" width="53" height="41" />
					</div>
					<div class="text1">个人资料</div>
					<div class="text2">{{me.phone?"":"待完善"}}</div>
					
				</div>
				<div class="classifyType" v-else @click="zhuaJiaInfo">
					<div class="imgs">
						<img src="../../public/static/images/persondata.png" width="53" height="41" />
					</div>
					<div class="text1">专家咨询</div>
					<div class="text2">已服务:{{me.zj_real_count}}人</div>
					
				</div>
				<div class="classifyType" v-if="me.zj_status>0" @click="myInfo2">
					<div class="imgs">
						<img src="../../public/static/images/persondata.png" width="53" height="41" />
					</div>
					<div class="text1">个人资料</div>
					<div class="text2">{{me.phone?"":"待完善"}}</div>
					
				</div>
				<div v-else class="classifyType" @click="toMessage">
					<div class="imgs">
						<img src="../../public/static/images/custom.png" width="46" height="46" />
					</div>
					<div class="text1">专享客服</div>
					<div class="text2">欢迎致电</div>
					<div class="redpoint" v-if="msgCount>0">{{msgCount}}</div>
				</div>
				<div class="classifyType" @click="pointChange">
					<div class="imgs">
						<img src="../../public/static/images/points.png" width="45" height="46" />
					</div>
					<div class="text1" style="padding-top: 19px;">积分</div>
					<div class="text1" style="padding-top: 1px;">使用说明</div>
					<div class="text2">共{{me.nowscore}}积分</div>
				</div>
			</div>
			<div class="classifyList">
				<div class="classifyType" @click="toOrder">
					<div class="imgs">
						<img src="../../public/static/images/orderimg.png" width="44" height="45" />
					</div>
					<div class="text1">我的订单</div>
					<div class="text2">共{{me.ordercount}}笔订单</div>
				</div>
				<div class="classifyType" @click="returnPro">
					<div class="imgs">
						<img src="../../public/static/images/returns.png" width="46" height="46" />
					</div>
					<div class="text1" style="padding-top: 19px;">退换货</div>
					<div class="text1" style="padding-top: 1px;">使用说明</div>
					<div class="text2">查看详情</div>
				</div>
				<div class="classifyType" @click="tomyinvite">
					<div class="imgs">
						<img src="../../public/static/images/invite.png" width="53" height="45" />
					</div>
					<div class="text1" style="padding-top: 19px;">查看</div>
					<div class="text1" style="padding-top: 1px;">邀请好友</div>
					<div class="text2">{{me.invitecount}}位好友</div>
				</div>
			</div>
		</div>
		<div id="qrcodebox" style="height: 100%; width: 100%; position: fixed; top: 0; background: rgba(0,0,0,0.4); z-index: 99;opacity:0; display: none;">

			<div style="height: 15%;"></div>
			<div style="height: 12%;width: 68%; text-align: right; margin: 0 auto; overflow: hidden;">
				<svg @click="HideQrcode" class="icon" style="width:40px; height: 40px;vertical-align: middle;fill: #fff;overflow: hidden;"
				 viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="9169">
					<path d="M519.168 926.72C283.648 926.72 92.16 735.232 92.16 499.712 92.16 263.168 283.648 71.68 519.168 71.68c235.52 0 428.032 191.488 428.032 428.032 0 235.52-192.512 427.008-428.032 427.008z m0-824.32C300.032 102.4 122.88 280.576 122.88 499.712s178.176 397.312 397.312 397.312 397.312-178.176 397.312-397.312C916.48 280.576 738.304 102.4 519.168 102.4zM351.232 688.128c-5.12 0-10.24-2.048-14.336-6.144-8.192-8.192-8.192-20.48 0-28.672l329.728-338.944c8.192-8.192 20.48-8.192 28.672 0 8.192 8.192 8.192 20.48 0 28.672L366.592 681.984c-4.096 4.096-9.216 6.144-15.36 6.144zM686.08 687.104c-5.12 0-10.24-2.048-14.336-6.144L342.016 342.016c-8.192-8.192-8.192-21.504 0-28.672s21.504-8.192 28.672 0l329.728 338.944c8.192 8.192 8.192 21.504 0 28.672-4.096 4.096-9.216 6.144-14.336 6.144z"
					 p-id="9170"></path>
				</svg>
				<div style="width: 100%;">
					<div style="height:100px; width: 2px; background: #fff; float: right; margin-right: 19px; margin-top: -5px;"></div>
				</div>
			</div>
			<div style="width: 70%; margin: 0 auto; margin-top: -20px; position: relative;">
				<img src="../../public/static/images/qrcodebg.png" style="width: 100%;" />
				<div style="position: absolute; width: 60%;  top: 0; left:13%; top: 17%; background: #fff; border: 15px solid #fff; border-radius: 4px;">
					<div id="qrcode"></div>
				</div>
			</div>

		</div>
		<div id="qrcodeboxmy" style="height: 100%; width: 100%; position: fixed; top: 0; background: rgba(0,0,0,0.4); z-index: 99;opacity:0; display: none;">
		
			<div style="height: 15%;"></div>
			<div style="height: 12%;width: 68%; text-align: right; margin: 0 auto; overflow: hidden;">
				<svg @click="HideQrcodeMy" class="icon" style="width:40px; height: 40px;vertical-align: middle;fill: #fff;overflow: hidden;"
				 viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="9169">
					<path d="M519.168 926.72C283.648 926.72 92.16 735.232 92.16 499.712 92.16 263.168 283.648 71.68 519.168 71.68c235.52 0 428.032 191.488 428.032 428.032 0 235.52-192.512 427.008-428.032 427.008z m0-824.32C300.032 102.4 122.88 280.576 122.88 499.712s178.176 397.312 397.312 397.312 397.312-178.176 397.312-397.312C916.48 280.576 738.304 102.4 519.168 102.4zM351.232 688.128c-5.12 0-10.24-2.048-14.336-6.144-8.192-8.192-8.192-20.48 0-28.672l329.728-338.944c8.192-8.192 20.48-8.192 28.672 0 8.192 8.192 8.192 20.48 0 28.672L366.592 681.984c-4.096 4.096-9.216 6.144-15.36 6.144zM686.08 687.104c-5.12 0-10.24-2.048-14.336-6.144L342.016 342.016c-8.192-8.192-8.192-21.504 0-28.672s21.504-8.192 28.672 0l329.728 338.944c8.192 8.192 8.192 21.504 0 28.672-4.096 4.096-9.216 6.144-14.336 6.144z"
					 p-id="9170"></path>
				</svg>
				<div style="width: 100%;">
					<div style="height:100px; width: 2px; background: #fff; float: right; margin-right: 19px; margin-top: -5px;"></div>
				</div>
			</div>
			<div style="width: 70%; margin: 0 auto; margin-top: -20px; position: relative;">
				
				<div style=" width: 70%;   margin-left:11%; background: #fff; border: 15px solid #fff; border-radius: 4px;">
					<div id="qrcodemy" @click="uploadWxqrcode">
						<img v-if="DataList.length>0&&DataList[0].unumber" :src="DataList[0].unumber" style="width: 100%; vertical-align: top;" />
						<div v-else  style="text-align: center;">
							点击上传
						</div>
					</div>
				</div>
			</div>
			
		</div>
		<div style="display: none;">
			<input type="file" id="fileupload" @change="uploadImage" />
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				DataList: [],
				couponCount: 0,
				msgCount:0
			}
		},
		mounted() {
			this.GetMy()
		},
		methods: {
			GetMy() {
				$("#loading").show()
				this.$store.dispatch("GET", {
					url: "/api/wx/?action=me"
				}).then(result => {
					this.DataList = result.data.data.rows
				
					if (!window.QRCode) {
						

							var qrcode = new QRCode($("#qrcode")[0], {
								width: $(window).width() * 0.42, //设置宽高
								height: $(window).width() * 0.42
							});
							qrcode.makeCode(this.DataList[0].qrcodeurl);

					
					} else {
						var qrcode = new QRCode($("#qrcode")[0], {
							width: $(window).width() * 0.42, //设置宽高
							height: $(window).width() * 0.42
						});
						qrcode.makeCode(this.DataList[0].qrcodeurl);
					}
					this.getCoupon();
					this.getMsgs();
					$("#loading").hide()

				})

			},
			getCoupon() {
				this.$store.dispatch("GET", {
					url: "/api/wx/?action=mycoupon"
				}).then(res => {
					this.couponCount = res.data.data.rows.length


				})
			},
			//查看我的资料
			myInfo2() {
				this.$router.push('/myinfo');
			},
			//查看我的订单
			toOrder() {
				this.$router.push('/order');
			},
			//查看邀请好友
			tomyinvite() {
				this.$router.push('/myinvite');
			},
			//退换货说明
			returnPro() {
				this.$router.push('/returnpro');
			},
			//积分使用
			pointChange() {
				this.$router.push('/pointchange');
			},
			toMessage() {
				this.$router.push('/message');
			},
			myCoupon() {
				this.$router.push('/coupon');
			},
			HideQrcode() {
				$("#qrcodebox").animate({
					translate3d: "0,-100%,0",
					opacity: 0
				}, 300, "esae-out", function() {
					$(this).hide()
				})
			},
			HideQrcodeMy(){
				$("#qrcodeboxmy").animate({
					translate3d: "0,-100%,0",
					opacity: 0
				}, 300, "esae-out", function() {
					$(this).hide()
				})
			},
			ShowQrcode() {
				$("#qrcodebox").animate({
					translate3d: "0,-100%,0",
					opacity: 0
				}, 10, "linear", function() {
					$(this).show().animate({
						translate3d: "0,0%,0",
						opacity: 1
					}, 300, "ease-in", function() {})
				})
			},
			ShowMyQrcode() {
				$("#qrcodeboxmy").animate({
					translate3d: "0,-100%,0",
					opacity: 0
				}, 10, "linear", function() {
					$(this).show().animate({
						translate3d: "0,0%,0",
						opacity: 1
					}, 300, "ease-in", function() {})
				})
			},
			ShowYwyQrcode(){
					
				if(this.DataList[0].ywyqrcode!=""){
					this.DataList[0].unumber = this.DataList[0].ywyqrcode;
					$("#qrcodeboxmy").animate({
						translate3d: "0,-100%,0",
						opacity: 0
					}, 10, "linear", function() {
						$(this).show().animate({
							translate3d: "0,0%,0",
							opacity: 1
						}, 300, "ease-in", function() {})
					})
				}
				
			},
			uploadImage(e){
				this.uploadOss({file:e.target.files[0],onSuccess:e=>{
					console.log(e)
					this.$store.dispatch("GET", {
						url: "/api/wx/?action=wxqrcode&wxqrcode="+encodeURIComponent(e.src)
					}).then(res => {
						this.GetMy();
					})
				}})
			},
			uploadWxqrcode(){
				if(this.DataList[0].groupid==1){
					document.getElementById('fileupload').click()
				}
			},
			getMsgs(){
				this.$store.dispatch("GET", {
				  url: "/api/chat/?action=getnoread"
				}).then(result => {
				 this.msgCount = result.data
				})
			},
			zhuaJiaInfo(){
				this.$router.push("/zjcenter")
			},
			refreshToken(){
				localStorage.clear()
				window.location.reload()
			}
		}
	}
</script>

<style scoped="scoped">
	.myCenter {
		height: 100%;
		width: 100%;
	}

	.userInfo {
		height: 203px;
		background: -webkit-gradient(linear, left top, right bottom, color-stop(0%, #ffdaf0), color-stop(100%, #85b1f7));
		text-align: center;
		position: relative;
	}

	.code {
		position: absolute;
		top: 22px;
		right: 30px;
	}
	.code2 {
		position: absolute;
		top: 62px;
		right: 30px;
		font-size: 12px;
		text-align: center;
		color: #FFFFFF;
		line-height: 14px;
	}

	.userImg {
		padding-top: 22px;
	}

	.userName {
		font-size: 20px;
		color: #FFFFFF;
	}

	.userPoint {
		font-size: 14px;
		color: #fff;
		padding-top: 5px;
	}

	.classifyList {
		padding-top: 15px;
		margin-left: 7px;
		display: flex;
	}

	.classifyType {
		flex: 1;
		margin-right: 7px;
		height: 140px;
		border-radius: 5px;
		background: #FFFFFF;
		text-align: center;
		padding-top: 50px;
		position:relative;
	}

	.imgs {
		height: 46px;
	}

	.text1 {
		font-size: 15px;
		color: #474747;
		padding-top: 26px;
	}

	.text2 {
		font-size: 11px;
		color: #919090;
	}
	.redpoint{
		width:20px;
		height:20px;
		background:#fe0346;
		color:#fff;
		border-radius:50%;
		position:absolute;
		right:10px;
		top:10px;
		text-align:center;
		line-height:20px;
		font-size:12px;
	}
	
</style>
